<template>
    <div>
        <Header :data="{getEvaluation}"/>
        <div class="md-layout md-gutter m-5 pb-3 mt-6 mb-0" style="border-bottom: 1px solid #ceb6b6">
            <div class="md-layout-item md-size-35">
                <h3 class="text-gray text-bold">Quiz Question Details</h3> 
            </div>
            <div class="md-layout-item md-size-50">
                <h3 class="text-gray text-bold">Your Answer</h3>
            </div>
            <div class="md-layout-item md-size-15 text-right">
                <h3 class="text-gray text-bold">Result</h3>
            </div>
        </div>
        <div v-for="items in getEvaluation.quizzes" :key="items.id" class="md-layout md-gutter m-5 pb-2 mb-3 mt-3" style="border-bottom: 1px solid #ceb6b6">
            <div class="md-layout-item md-size-35">
                <h3 class="text-black font-bold" v-html="items.quiz_details.index"></h3> 
                <p class="md-sl__font_600 text-victoria mt-1">Question ID: {{items.quiz_details.quiz_id}}</p>
            </div>
            <div class="md-layout-item md-size-50">
                <span class="flex align-center" v-for="(answer,index) in items.answer_details" :key="answer.id">
                    <div v-if="items.quiz_details.answer_type === 'MCQ-CHOOSE-SINGLE-ANSWER'">
                        <md-radio disabled v-model="answer.is_correct_answer" :value="answer.is_correct_answer" v-if="answer.is_correct_answer === true" class="md-primary ml-2"> </md-radio>
                    </div>
                    <div v-if="items.quiz_details.answer_type === 'MCQ-CHOOSE-MULTIPLE-ANSWER'">
                        <md-checkbox disabled v-model="answer.is_correct_answer" :value="answer.is_correct_answer" v-if="answer.is_correct_answer === true" class="md-primary ml-2"> </md-checkbox>
                    </div>
                    <div v-if="answer.is_correct_answer === true">
                        <p v-if="answer.is_correct_answer === true">{{answer.value}}</p>
                    </div>
                    <div v-if="index === 1 && items.quiz_details.answer_type === 'TEXT'">
                       <p>{{items.answer_details}}</p>
                    </div>
                </span>
            </div>
            <div class="md-layout-item md-size-15 text-right">
                <h3 class="text-bold" :class="items.result === 'wrong' ? 'text-danger' : items.result === 'correct' ? 'text-success' : 'text-tahiti-gold'">{{items.result === 'wrong' ? 'Wrong' : items.result === 'correct' ? 'Correct' : 'Partial Correct'}}</h3>
            </div>
        </div>
        <Footer :layout="layout"/>
    </div>
</template>

<script>
import Header from "./Headers.vue";
import Footer from "./Footers.vue";
import { mapGetters, mapActions} from "vuex";
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            radio: 0,
            layout: {
                button: 'View Details Result',
                name: 'details-result.evaluation'
            }
        }
    },
    computed: {
        ...mapGetters({
            getEvaluation: "quiz/getEvaluation",
      
        }),
        quizId() {
            return this.$route.params.id
        }
    },
    methods: {
        ...mapActions({
            actEvaluationData: "quiz/actEvaluationData",
        }),  
    },
    created() {
        this.actEvaluationData(this.quizId);
    },
};
</script>
