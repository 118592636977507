<template>
<div>
   <Alert classObject="rounded-lg pt-4 mx-4 flex justify-between ev-button">
        <md-button @click="Prev()"  class="rounded br-6 m-1 b-d p-2 text-white back-blue">
            Retake Quiz
        </md-button>
        <md-button @click="nextButton" class="br-6 rounded m-1 b-d p-2 text-white bg-victoria">
           {{layout.button}}
        </md-button>
        
    </Alert> 
</div>
</template>

<script>
import { Alert} from '@/components';
  export default {
     components : {
        Alert
    },
    props: {
        layout: {
            default: true
        }
    },
    data() {
      return {
       
      }
    },
    computed: {
        quizId() {
            return this.$route.params.id
        }
    },
    methods:{
        nextButton(){
            this.$router.push({
              name: this.layout.name,
              params: {
                id: this.quizId
              }
            });
        },
        Prev(){
            this.$router.push({
                name: "student.quizTestPreview",
                // params: {
                // id: item.id,
                // },
            });
           localStorage.setItem('dailog', true);
        }
    }
}
</script>
<style scoped>
</style>