<template>
    <div class="evaluation_header">
        <Alert background-color="#6616d0" classObject="rounded-lg p-2">
            <div class="flex flex-wrap justify-between justify-sm-center justify-xs-center">
                <div class="mx-2 p-2">
                    <div class="text-white text-sm-center text-xs-center">
                        <div class="flex align-center">
                            <h3 class="text-uppercase text-white">{{ data.getEvaluation.quiz_s_title}}</h3>
                            <span class="pl-2 pr-2 m-2" style="background:#a40fbc;border-radius: 4px;">{{ data.getEvaluation.quiz_s_id}}</span>
                        </div>
                        <div class="flex align-center">
                            <span class="font-bold text-xl md-eval__back">{{ data.getEvaluation.marks_gained }}</span>
                            <small class="pl-2 text-gray1">OVERALL SCORE</small>
                        </div>
                    </div>
                </div>
                <div class="mx-2 flex align-center">
                    <div class="md-grid_right">
                        <span class="font-bold text-white">{{ data.getEvaluation.student_name}}</span>
                        <span class="text-gray1">{{ data.getEvaluation.created_at }}</span>
                    </div>
                    <div class="p-3 rounded text-white">
                        <md-avatar class="md-avatar-icon md-large__img">
                            <img src="/images/result-sheet.png" alt="" class="">
                        </md-avatar>
                    </div>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
export default {
    components : {
        Alert
    },
    props: {
        data: {
            type: Object,
        }
    },
    data() {
        return {
          
        }
    },
    methods: { 
       
    }
}
</script>
